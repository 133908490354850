import React from 'react';
import { View } from '../../../ui-elements/View';
import AppButtonNew from '../../AppButtonNew';
import { IconButton } from '@mui/material';
import PlatformSection from '../../../PlatformSection';
import { isAndroid, isIOS } from 'react-device-detect';
import * as Icons from "../../../../Icons";
import './topbar.scss';

interface PlatformItem {
	icon: any;
}
const ListenOnItems: PlatformItem[] = [
	{
		icon: Icons.ICON_HEADER1
	},
	{
		icon: Icons.ICON_HEADER2
	},
	{
		icon: Icons.ICON_HEADER3
	},
	{
		icon: Icons.ICON_HEADER4
	},
	{
		icon: Icons.ICON_HEADER5
	},
	{
		icon: Icons.ICON_HEADER7
	},
	{
		icon: Icons.ICON_HEADER8
	},
	{
		icon: Icons.ICON_HEADER9
	},
	{
		icon: Icons.ICON_HEADER10
	},
	{
		icon: Icons.ICON_HEADER11
	},
	{
		icon: Icons.ICON_HEADER12
	},
	{
		icon: Icons.ICON_HEADER13
	},
	{
		icon: Icons.ICON_HEADER14
	},
	{
		icon: Icons.ICON_HEADER15
	},
	{
		icon: Icons.ICON_HEADER16
	},
	{
		icon: Icons.ICON_HEADER17
	},
	{
		icon: Icons.ICON_HEADER18
	},
	{
		icon: Icons.ICON_HEADER19
	},
	{
		icon: Icons.ICON_HEADER20
	},
	{
		icon: Icons.ICON_HEADER21
	},
	// {
	// 	icon: Icons.ICON_HEADER22
	// },
	{
		icon: Icons.ICON_HEADER23
	},
	{
		icon: Icons.ICON_HEADER24
	},
	{
		icon: Icons.ICON_HEADER25
	},
	{
		icon: Icons.ICON_HEADER26
	},
	{
		icon: Icons.ICON_HEADER27
	},
	{
		icon: Icons.ICON_HEADER28
	},
	{
		icon: Icons.ICON_HEADER29
	},
	{
		icon: Icons.ICON_HEADER30
	},
];


interface RenderTopBarProps {
	smallScreen?: boolean,
	mobileScreen?: boolean,
	drawerIconClick(): void
}

export default class RenderTopbar extends React.Component<RenderTopBarProps>{

	constructor(props: RenderTopBarProps) {
		super(props)
	}

	render() {
		return (
			<View>
				<div className='topbar-container'>
					<div className="topbar-inner-container">
						{this.props.smallScreen && <>
							<IconButton
								onClick={() => this.props.drawerIconClick()}
								style={{ color: 'white', display: 'flex', justifyContent: 'flex-start', marginRight: 5 }}
							>
								<img src={Icons.ICON_BURGER_MENU} width={28} height={20} />
							</IconButton>
							{this.props.mobileScreen &&
								<div className="dash-logo">
									<img
										className="LogoDash"
										src={Icons.ICON_LOGO_DASH_WHITE}
										alt="Dash Logo"
										width={80}
										height={22}
										style={{ objectFit: 'contain', marginRight: 53 }}
									/>
								</div>
							}
						</>}
						{!this.props.mobileScreen &&
							<div className="available-everywhere">
								Available Everywhere
							</div>
						}
						{!this.props.mobileScreen &&
							<div className="header-ticker">
								<div className="header-test" />
								<PlatformSection logoSlider items={ListenOnItems} apps />
							</div>
						}
						{!this.props.mobileScreen &&
							<div className='header-app-container'>
								<AppButtonNew appStore classname='header-app-button first' />
								<AppButtonNew playStore classname='header-app-button' />
							</div>
						}
						{this.props.mobileScreen &&
							<div>
								<div className="header-mobile-button-container">
									{isIOS &&
										<AppButtonNew appStore />
									}
									{isAndroid &&
										<AppButtonNew playStore mobileScreen={this.props.mobileScreen} />
									}
								</div>
							</div>
						}
					</div>
				</div>
			</View>
		)
	}
}
