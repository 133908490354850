import React from 'react'
import classnames from 'classnames'
import classes from "./AppButton.module.css";

interface Props {
    classname?: any,
    onClick: () => void,
    disabled?: boolean,
    style?: any
    children?: React.ReactNode
}
const AppButton: React.FC<Props> = (props) => {
    const disabled = Boolean(props.disabled)
    return (
        <div className={classnames(classes.appButton, !disabled && classes.clickable, props.classname)}
            onClick={() => {
                if (!disabled) {
                    props.onClick()
                }
            }}
            style={props.style}>
            {props.children}
        </div>
    )
}

export default AppButton
