import React, {useEffect, useState, useRef, CSSProperties} from 'react';
import { Grid, CircularProgress, Hidden } from '@mui/material';
import AppPage from '../AppPage';
import HeadlineText from '../../components/views/HeadlineText';
import AppText from '../../components/views/AppText';
import Spacer from '../../components/ui-elements/Spacer';
import { DashThemeLight } from '../../Themes';
import troubleshootingText from './troubleshooting'
import contestRulesText from './contest_rules'
import privacyPolicyText from './privacy_policy'
import termsOfServiceText from './terms_of_service'
import { Page, EVENT_SCROLL, navigationEventEmitter } from '../../components/AppDrawer';
import ContactForm from './ContactForm'
import { AppsPage } from '../apps/AppsPage';
import { useSelector, useDispatch } from 'react-redux'
import { updateSettings, IsSideBarOpenSelector } from '../../reducers/LocalSettingsReducer';
import { CORPORATE } from '../../Icons';
import HomeTeaserNew from '../home/HomeTeaserNew';
import Headline from '../../components/views/Headline';
import { getActivePage, setPageActive } from '../../components/views/AppDrawerComponents/Functions';

export const ContactPage: React.FC<{ pathname?: string }> = (props) => {

    const dispatch = useDispatch()
    const isSideBarOpen = useSelector(IsSideBarOpenSelector);
    const [scroller, setScroller] = useState(true)

    const appSection: any = useRef(null)
    const contactSection: any = useRef(null)
    const troubleshootingSection: any = useRef(null)
    const contestSection: any = useRef(null)
    const privacySection: any = useRef(null)
    const tosSection: any = useRef(null)

    useEffect(() => {
        if (!isSideBarOpen) {
            dispatch(updateSettings({ sideBar: true }))
        }
    }, [isSideBarOpen, dispatch])

    // useEffect(() => {
    //     configureAnchors({
    //         offset: -130,
    //         scrollDuration: 300
    //     })
    //     goToAnchor('apps')
    // }, [])

    useEffect(() => {
        let activePage = getActivePage()
        const scroll = (sec) => {
            window.scrollTo({
                behavior: 'smooth',
                left: 0,
                top: sec && sec.current && sec.current.offsetTop + 150,
            })
        }
        switch (activePage) {
            case Page.TERMS_OF_SERVICE: {
                // goToAnchor('tos')
                scroll(tosSection)
                break;
            }
            case Page.PRIVACY_POLICY: {
                // goToAnchor('privacy')
                scroll(privacySection)
                break;
            }
            default: {
                window.scrollTo(0, 0)
                break;
            }
        }
    }, [])

    useEffect(() => {
        let activePage = getActivePage()
        if (activePage === Page.ABOUT) {
            setPageActive(Page.APPS)
        }
    }, [])

    useEffect(() => {
        const scrollToListener = (section: string) => {
            setScroller(false)
            let switchTo: any = ''
            switch (section) {
                case 'apps': {
                    switchTo = appSection;
                    setPageActive(Page.APPS)
                    break;
                }
                case 'contact': {
                    switchTo = contactSection;
                    setPageActive(Page.CONTACT)
                    break;
                }
                case 'troubleshooting': {
                    switchTo = troubleshootingSection;
                    setPageActive(Page.TROUBLESHOOTING)
                    break;
                }
                case 'contestrules': {
                    switchTo = contestSection;
                    setPageActive(Page.CONTEST_RULES)
                    break;
                }
                case 'privacy': {
                    switchTo = privacySection;
                    setPageActive(Page.PRIVACY_POLICY)
                    break;
                }
                case 'tos': {
                    switchTo = tosSection;
                    setPageActive(Page.TERMS_OF_SERVICE)
                    break;
                }
            }
            const timer = setTimeout(() => {
                window.scrollTo({
                    behavior: 'smooth',
                    left: 0,
                    top: switchTo && switchTo.current && switchTo.current.offsetTop - 120,
                })
            }, 200);
            return () => clearTimeout(timer)
        }
        navigationEventEmitter.addListener(EVENT_SCROLL, scrollToListener)
        return () => {
            navigationEventEmitter.removeListener(EVENT_SCROLL, scrollToListener)
        }
    })

    useEffect(() => {
        let appSectionPos = appSection && appSection.current && appSection.current.offsetTop
        let contactSectionPos = contactSection && contactSection.current && contactSection.current.offsetTop
        let troubeSectionPos = troubleshootingSection && troubleshootingSection.current && troubleshootingSection.current.offsetTop
        let contestSectionPos = contestSection && contestSection.current && contestSection.current.offsetTop
        let privacySectionPos = privacySection && privacySection.current && privacySection.current.offsetTop
        let tosSectionPos = tosSection && tosSection.current && tosSection.current.offsetTop
        let offset = 120


        const listener = () => {
            if (appSectionPos && contactSectionPos && troubeSectionPos && contestSectionPos && privacySectionPos && tosSectionPos && scroller) {
                if (window.pageYOffset <= contactSectionPos - offset && appSectionPos !== contactSectionPos && window.pageYOffset <= appSectionPos) {
                    // if(window.location.hash !== 'favorites'){
                    //     window.location.hash = 'favorites'
                    // }
                    // window.location.pathname += '/favorites'

                    setPageActive(Page.APPS)
                }
                else if ((window.pageYOffset > (appSectionPos - offset) && window.pageYOffset < troubeSectionPos - offset && window.pageYOffset >= contactSectionPos - offset)) {
                    // if(window.location.hash !== 'genres'){
                    //     window.location.hash = 'genres'
                    // }
                    // window.location.pathname += '/genres'

                    setPageActive(Page.CONTACT)
                }
                else if ((window.pageYOffset > (contactSectionPos - offset) && window.pageYOffset < contestSectionPos - offset && window.pageYOffset >= troubeSectionPos - offset)) {
                    // if(window.location.hash !== 'genres'){
                    //     window.location.hash = 'genres'
                    // }
                    // window.location.pathname += '/genres'
                    setPageActive(Page.TROUBLESHOOTING)
                }
                else if ((window.pageYOffset > (troubeSectionPos - offset) && window.pageYOffset < privacySectionPos - offset && window.pageYOffset >= contestSectionPos - offset)) {
                    // if(window.location.hash !== 'genres'){
                    //     window.location.hash = 'genres'
                    // }
                    // window.location.pathname += '/genres'

                    setPageActive(Page.CONTEST_RULES)
                }
                else if ((window.pageYOffset > (contestSectionPos - offset) && window.pageYOffset < tosSectionPos - offset && window.pageYOffset >= privacySectionPos - offset)) {
                    // if(window.location.hash !== 'genres'){
                    //     window.location.hash = 'genres'
                    // }
                    // window.location.pathname += '/genres'

                    setPageActive(Page.PRIVACY_POLICY)
                }
                else if (window.pageYOffset >= tosSectionPos - offset) {
                    // if(window.location.hash !== 'allstations'){
                    //     window.location.hash = 'allstations'
                    // }
                    // window.location.pathname += '/allstations'

                    setPageActive(Page.TERMS_OF_SERVICE)
                }
            }
            if (!scroller) {
                const timer = setTimeout(() => {
                    setScroller(true)
                }, 500);
                return () => clearTimeout(timer);
            }
        }

        window.addEventListener('scroll', listener)
        return () => {
            window.removeEventListener('scroll', listener)
        }
    })

    return (
        <React.Fragment>
            <AppPage withLogo home>
                <Grid container direction="row" justifyContent="center">
                    <Grid item xs={12} sm={12}>
                        <HomeTeaserNew />
                    </Grid>
                    <Hidden smDown><Grid item sm={1}></Grid></Hidden>
                    <Grid item xs={12} sm={10}>
                        <span ref={appSection} />
                        <div>
                            <AppsPage />
                            <Spacer size={32} />
                        </div>
                        <span ref={contactSection} />
                        <div>
                            <ContactSection />
                            <Spacer size={16} />
                        </div>
                        <span ref={troubleshootingSection} />
                        <div>
                            <TroubleshootingSection />
                            <Spacer size={16} />
                        </div>
                        <span ref={contestSection} />
                        <div>
                            <ContestRulesSection />
                            <Spacer size={16} />
                        </div>
                        <span ref={privacySection} />
                        <div>
                            <PrivacyPolicySection />
                            <Spacer size={16} />
                        </div>
                        <span ref={tosSection} />
                        <div>
                            <TermsOfServiceSection />
                            <Spacer size={16} />
                        </div>

                    </Grid>
                    <Hidden smDown><Grid item sm={1}></Grid></Hidden>
                </Grid>
            </AppPage>
        </React.Fragment>
    )
}

export const ContactSection: React.FC = (props) => {

    return (
        <AppPage withLogo home>
            <Grid container direction="row" justifyContent="center">
                {/* <Grid item xs={12} sm={12}>
                    <HomeTeaserNew />
                </Grid> */}
                <Hidden smDown><Grid item sm={1}></Grid></Hidden>
                <Grid item xs={12} sm={10} style={{ minHeight: '100vh' }}>
                    <Spacer size={16} />
                    <Headline title="Contact" center />
                    <Spacer size={12} />
                    <AppText>If you’ve noticed any errors or have an idea on how to make DASH even better, please let us know! Just shoot us an e-mail to info@dashradio.com or use the contact form below!</AppText>
                    <Spacer size={16} />
                    <ContactForm />
                    <Spacer size={32} />
                </Grid>
                <Hidden smDown><Grid item sm={1}></Grid></Hidden>
            </Grid>
        </AppPage>
        // <>
        //     <HeadlineText>Contact</HeadlineText>
        //     <HeadlineSeparator />
        //     <Spacer size={12} />
        //     <AppText>If you’ve noticed any errors or have an idea on how to make DASH even better, please let us know! Just shoot us an e-mail to info@dashradio.com or use the contact form below!</AppText>
        //     <Spacer size={16} />
        //     <ContactForm />
        //     <Spacer size={32} />
        // </>
    )
}

export const TroubleshootingSection: React.FC = (props) => (
    <AppPage withLogo home>
        <Grid container direction="row" justifyContent="center">
            {/* <Grid item xs={12} sm={12}>
                <HomeTeaserNew />
            </Grid> */}
            <Hidden smDown><Grid item sm={1}></Grid></Hidden>
            <Grid item xs={12} sm={10} style={{ minHeight: '100vh' }}>
                <Spacer size={16} />
                <DynamicSection text={troubleshootingText} title="Troubleshooting" />
            </Grid>
            <Hidden smDown><Grid item sm={1}></Grid></Hidden>
        </Grid>
    </AppPage>
)
export const ContestRulesSection: React.FC = (props) => (
    <AppPage withLogo home>
        <Grid container direction="row" justifyContent="center">
            {/* <Grid item xs={12} sm={12}>
                <HomeTeaserNew />
            </Grid> */}
            <Hidden smDown><Grid item sm={1}></Grid></Hidden>
            <Grid item xs={12} sm={10} style={{ minHeight: '100vh' }}>
                <Spacer size={16} />
                <DynamicSection text={contestRulesText} title="Contest Rules" />
            </Grid>
            <Hidden smDown><Grid item sm={1}></Grid></Hidden>
        </Grid>
    </AppPage>

)
export const PrivacyPolicySection: React.FC = (props) => (
    <AppPage withLogo home>
        <Grid container direction="row" justifyContent="center">
            {/* <Grid item xs={12} sm={12}>
                <HomeTeaserNew />
            </Grid> */}
            <Hidden smDown><Grid item sm={1}></Grid></Hidden>
            <Grid item xs={12} sm={10} style={{ minHeight: '100vh' }}>
                <Spacer size={16} />
                { privacyPolicyText({policyId: 46020621}) }
            </Grid>
            <Hidden smDown><Grid item sm={1}></Grid></Hidden>
        </Grid>
    </AppPage>
)
export const TermsOfServiceSection: React.FC = (props) => (
    <AppPage withLogo home>
        <Grid container direction="row" justifyContent="center">
            {/* <Grid item xs={12} sm={12}>
                <HomeTeaserNew />
            </Grid> */}
            <Hidden smDown><Grid item sm={1}></Grid></Hidden>
            <Grid item xs={12} sm={10} style={{ minHeight: '100vh' }}>
                <Spacer size={16} />
                <DynamicSection text={termsOfServiceText} title="Terms Of Service" />
            </Grid>
            <Hidden smDown><Grid item sm={1}></Grid></Hidden>
        </Grid>
    </AppPage>
)
// const CorporateSection: React.SFC = (props) => (
//     <DynamicSection text={termsOfServiceText} title="Corporate" corporate />
// )

const DynamicSection: React.FC<{ text: string, title: string, corporate?: boolean }> = (props) => {
    const textStyle: CSSProperties = {
        whiteSpace: "pre-line",
        paddingLeft: 20,
        paddingRight: 20
    };

    const handleCorporateClick = () => {
        window.open("https://about.dashradio.com", "_blank")
    }

    return (
        <>{!props.corporate &&
            <>
                {/* <HeadlineText>{props.title}</HeadlineText> */}
                <Headline title={props.title} center />
                {/* <HeadlineSeparator /> */}
                <Spacer size={12} />
                <AppText style={textStyle}>
                    {
                        props.text || <DashThemeLight><CircularProgress /></DashThemeLight>
                    }
                </AppText>
                <Spacer size={16} />
            </>}
            {props.corporate &&
                <>
                    <HeadlineText>{props.title}</HeadlineText>
                    <Spacer size={32} />
                    <img width="100%" onClick={handleCorporateClick} src={CORPORATE} alt="corporate" />
                    <Spacer size={16} />
                </>
            }
        </>
    )
}

export default React.memo(ContactPage)
