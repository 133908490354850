import React from "react";
import { useMediaQuery } from "@mui/material";
import ResponsivePage from "../../components/responsive-ui/ResponsivePage";
import HeadlineBigText from "../../components/views/HeadlineBigText";
import HeadlineSubText from "../../components/views/HeadlineSubText";
import AppButton from "../../components/views/AppButton";
import AspectRatioView from "../../components/ui-elements/AspectRatioView";
import { DashThemeLight } from "../../Themes";
import { isChrome, isFirefox } from "react-device-detect";
import * as Icons from "../../Icons";
import { Page } from "../../components/AppDrawer";
import { setPageActive } from "../../components/views/AppDrawerComponents/Functions";
import classes from '../PageStyles.module.scss';

const HomeTeaserNew: React.FC<{ home?: boolean }> = props => {
    const smallTablet = useMediaQuery("(max-width: 680px)");
    const mobileScreen = useMediaQuery('(max-width: 480px)')

    return (
        <ResponsivePage>{device => (
            <section style={{ position: 'relative', zIndex: 1, marginLeft: -1 }}>
                <div className={classes.videoOverlay} style={{ transition: '1s', width: '100%' }}>
                    <div className={classes.overlay}>
                        <HeadlineBigText className={classes.headLineBigText} style={{ height: '4rem', ...((mobileScreen || smallTablet) && { fontSize: '2rem', lineHeight: '2rem', height: 'unset' }) }}>PREMIUM RADIO. FREE.</HeadlineBigText>
                        <HeadlineBigText className={classes.headLineBigText} style={{ ...((mobileScreen || smallTablet) && { fontSize: '2rem', lineHeight: '2rem', height: 'unset' }) }}>NO COMMERCIALS.</HeadlineBigText>
                        <HeadlineSubText title style={{ fontSize: '1.5rem', textAlign: 'center', marginTop: '2rem', lineHeight: '2rem', ...((mobileScreen || smallTablet) && { fontSize: '1.2rem', lineHeight: '1.4rem' }) }}>Dash is available free on over 50 platforms including smart speakers,<br></br>connected cars, mobile, smart tvs, and more.</HeadlineSubText>
                        <div style={{ flexDirection: 'row', display: 'flex' }}>
                            <AppButton style={{ width: '12rem', padding: '8px 16px', borderRadius: '16px', marginTop: '1.5rem', backgroundColor: '#FF3F90', color: 'white', marginRight: '1rem' }} onClick={() => setPageActive(Page.RADIO)}>Listen now</AppButton>
                            {props.home && <AppButton style={{ width: '12rem', padding: '8px 16px', borderRadius: '16px', marginTop: '1.5rem', backgroundColor: 'white', color: '#034F66', marginLeft: '1rem' }} onClick={() => setPageActive(Page.ABOUT)}>Where to Listen</AppButton>}
                        </div>
                    </div>
                </div>
                <AspectRatioView w={16} h={9}>
                    <DashThemeLight>
                        {(isChrome || isFirefox) && (
                            <video
                                playsInline
                                // playsinline
                                width="100%"
                                height="100%"
                                muted
                                preload="auto"
                                autoPlay
                                poster={Icons.PREVIEW_POST}
                                controlsList="nofullscreen"
                                onTimeUpdate={e => {
                                    if (
                                        Math.abs(
                                            e.currentTarget.duration - e.currentTarget.currentTime
                                        ) < 0.5
                                    ) {
                                        e.currentTarget.currentTime = 0;
                                    }
                                }}
                                onContextMenu={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                                // style={{ position: 'absolute', bottom: 0, top: 0, width: '100%', height: '90vh', left: '50%', transform: 'translate(-50%)', transition: '1s' }}
                                style={{ position: 'absolute', bottom: 0, top: 0, width: '100%', left: '50%', transform: 'translate(-50%)', transition: '1s' }}
                            >
                                <source
                                    // src="https://dashradio-files.s3.amazonaws.com/video/Loop+%232++v2.mp4"
                                    src="https://dashradio-files.s3.amazonaws.com/video/Loop+v3.mp4"
                                    type="video/mp4"
                                />
                            </video>
                        )}
                        {(!isChrome && !isFirefox) && (
                            <video
                                webkit-playsinline="true"
                                playsInline
                                // playsinline
                                width="100%"
                                height="100%"
                                muted
                                preload="auto"
                                autoPlay
                                onTimeUpdate={e => {
                                    if (
                                        Math.abs(
                                            e.currentTarget.duration - e.currentTarget.currentTime
                                        ) < 0.5
                                    ) {
                                        e.currentTarget.currentTime = 0;
                                    }
                                }}
                                poster={Icons.PREVIEW_POST}
                                onContextMenu={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                                style={{ position: 'absolute', bottom: 0, top: 0, width: '100%', left: '50%', transform: 'translate(-50%)', transition: '1s' }}
                            >
                                <source
                                    // src="https://dashradio-files.s3.amazonaws.com/video/m3u/index.m3u8"
                                    src="https://dashradio-files.s3.amazonaws.com/video/m3u/index_v3.m3u8"
                                    type="application/x-mpegURL"
                                />
                            </video>
                        )}
                    </DashThemeLight>
                </AspectRatioView>
            </section>
        )}</ResponsivePage>
    )
}

export default React.memo(HomeTeaserNew)
