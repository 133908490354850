import React from 'react';
import ListenNowElement from './ListenNowElement'
import AppPlatformsElement from './AppPlatformsElement';
import Spacer from '../../components/ui-elements/Spacer';
import AppPage from '../AppPage';
import { Grid, Hidden } from '@mui/material';
import HomeTeaserNew from '../home/HomeTeaserNew';
import Headline from '../../components/views/Headline';

export const AppsPage: React.FC = (props) => {
    return (
        // <>
        //     <ListenNowElement />
        //     <Spacer size={32} />
        // <AppPlatformsElement />
        // </>
        <AppPage withLogo home>
            <Grid container direction="row" justifyContent="center">
                <Grid item xs={12} sm={12}>
                    <HomeTeaserNew />
                </Grid>
                <Grid item xs={12} sm={11} style={{ minHeight: '100vh' }}>
                    <Spacer size={32} />
                    <Headline title="Available everywhere" center />
                    <Spacer size={64} />
                    <AppPlatformsElement />
                </Grid>
            </Grid>
        </AppPage>
    )
}

export default React.memo(AppsPage)
