import React, { useState, useRef } from 'react';
import { IconButton, CircularProgress, useMediaQuery } from '@mui/material';
import Modal from '@mui/material/Modal';
import AppSimpleButton from './views/AppSimpleButton';
import AppSimpleInput from './views/AppSimpleInput';
import AppText from './views/AppText';
import { DashThemeLight, DashThemeDark } from '../Themes';
import { LoginAPI } from 'dash-dashradio-app-client-api';
import { useDispatch } from 'react-redux'
import { session } from '../App';
import { setSession } from '../reducers/LoginReducer';
import Headline2Text from './views/Headline2Text';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import { Page } from './AppDrawer';
import FadeInHOC from './ui-elements/FadeInHOC';
import { setPageActive } from './views/AppDrawerComponents/Functions';
import * as EmailValidator from 'email-validator';
import classes from "./SignupDialog.module.css";
export const SignupDialog: React.FC<{ onCloseClick: () => void, onBackClick?: () => void }> = (props) => {
    const { onCloseClick, onBackClick } = props

    const firstNameInput = useRef(null)
    const lastNameInput = useRef(null)
    const emailInput = useRef(null)
    const passwordInput = useRef(null)

    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(undefined)

    const dispatch = useDispatch()

    const handleSignup = () => {
        setLoading(true)

        LoginAPI.register(session)(email, password, firstName, lastName).then(data => {
            if (data && data.access_token) {
                session.session_token = data.access_token
                localStorage.setItem('userID', data.user.id.toString())
                setLoading(false)
                setError(undefined)

                //dispatch session to redux store
                dispatch(setSession(data.access_token, data.user))

                //finish dialog
                onCloseClick()
            }
        }).catch(err => {
            setLoading(false)
            setError(err)
        })
    }

    var errorMessage
    const status: any = (error && error!['status']) || 400
    switch (status) {
        case 403:
            errorMessage = "This email has already been taken."
            break
        default:
        case 400:
            if (password.length < 6) {
                errorMessage = "Please choose a password with at least 6 characters."
            } else if(!EmailValidator.validate(email)){
                errorMessage = "Please provide a valid email address."
            } else errorMessage = "Please check your input and fill in all fields."
            break
    }

    return (
        <div className='dialog-popups' style={{ overflowX: 'hidden' }}>
            <div className='popup-header-container'>
                <div className='popup-title'>Hey there!</div>
                <DashThemeDark>
                    <IconButton color="primary" onClick={() => onCloseClick()} style={{ padding: 0 }}>
                        <CloseIcon scale={24} />
                    </IconButton>
                </DashThemeDark>
            </div>

            <form className={classes.content}>
                <Headline2Text style={{color: 'white'}}>Register to win Prizes</Headline2Text>
                {/* <AppText >Create your free account now to enjoy all the amazing features Dash has to offer.</AppText> */}
                <div className="dialog-title">Create your free account now to enjoy all the amazing features Dash has to offer.</div>

                <div style={{ width: 64, paddingBottom: 12, paddingTop: 4 }}></div>

                <div className='simple-input-container popup'>
                    <AppSimpleInput
                        inputRef={firstNameInput}
                        placeholder="First name"
                        value={firstName}
                        onChange={(value) => setFirstName(value)}
                        onSubmit={() => {
                            lastNameInput && (lastNameInput.current as any).focus()
                        }}
                        popup />
                </div>
                <div className='simple-input-container popup'>
                    <AppSimpleInput
                        inputRef={lastNameInput}
                        placeholder="Last name"
                        value={lastName}
                        onChange={(value) => setLastName(value)}
                        onSubmit={() => {
                            emailInput && (emailInput.current as any).focus()
                        }}
                        popup />
                </div>
                <div className='simple-input-container popup'>
                    <AppSimpleInput
                        inputRef={emailInput}
                        placeholder="email@example.com"
                        value={email}
                        onChange={(value) => setEmail(value)}
                        onSubmit={() => {
                            passwordInput && (passwordInput.current as any).focus()
                        }}
                        popup />
                </div>
                <div className='simple-input-container popup'>
                    <AppSimpleInput password
                        inputRef={passwordInput}
                        placeholder="password"
                        value={password}
                        onChange={(value) => setPassword(value)}
                        onSubmit={handleSignup}
                        popup />
                </div>

                {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}> */}
                <div className="popup-button-container">
                    <AppSimpleButton disabled={loading} onClick={handleSignup} popup>Sign up</AppSimpleButton>
                    {
                        loading &&
                        <DashThemeLight>
                            <CircularProgress color="primary" style={{ width: 24, height: 24, margin: '0px 16px', color: '#ff3f90' }} />
                        </DashThemeLight>
                    }
                </div>

                <div style={{ minHeight: 75, paddingTop: 16, paddingBottom: 16 }}>
                    {
                        error && errorMessage && <AppText className={classes.error}>{errorMessage}</AppText>
                    }
                </div>
                <AppText>By signing up, you agree to our <span className={classes.link} onClick={() => setPageActive(Page.TERMS_OF_SERVICE)}>Terms and Conditions</span> and <span className={classes.link} onClick={() => setPageActive(Page.PRIVACY_POLICY)}>Privacy Policy</span>.</AppText>

            </form>

        </div>
    )
}

export const SignupDialogModal: React.FC<{ open: boolean, onClose: () => void, onBack?: () => void }> = (props) => {
    const handleClose = () => props.onClose()

    return (
        <Modal open={props.open} onClose={handleClose} >
            <div>
                <FadeInHOC>
                    <SignupDialog onCloseClick={handleClose} onBackClick={props.onBack} />
                </FadeInHOC>
            </div>
        </Modal>
    )
}

export default SignupDialogModal
