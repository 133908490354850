import {session} from "../App";
import {LoginAPI, User, PresetsAPI, LoginResponse} from "dash-dashradio-app-client-api";
import { setFavorites } from '../reducers/AllStationsReducer';
import {setDiscordCSRFToken, setSession} from '../reducers/LoginReducer';
import { OAuthProvider, signInWithPopup, getAuth, getAdditionalUserInfo } from 'firebase/auth';
import { v4 as uuidv4 } from "uuid";
import { Dispatch } from "redux";
import { setPageActive } from "../components/views/AppDrawerComponents/Functions";
import { Page } from "../components/AppDrawer";
import {CookieSetOptions} from "universal-cookie";
import {FailResponse} from "@greatsumini/react-facebook-login";

const discordUrl = (state) =>  'https://discord.com/api/oauth2/authorize?' +
    `response_type=code&client_id=774181965250428989&scope=identify%20email&state=${state}`+
    '&redirect_uri=https%3A%2F%2Fwww.dashradio.com%2Fdiscord-auth&prompt=consent'
    //'&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fdiscord-auth&prompt=consent'
export default class LoginHelper{
    public static discordLoginState?: string;
    private provider: OAuthProvider = new OAuthProvider('apple.com');
    private readonly dispatch: Dispatch<any>;
    private readonly setError: (error?: Error) => void;
    private readonly setLoading: (loading: boolean) => void;
    private readonly onClose: () => void;
    constructor(dispatch: Dispatch<any>, setError: (error?: Error) => void, setLoading: (loading: boolean) => void, onClose: () => void){
        this.dispatch = dispatch;
        this.setError = setError;
        this.setLoading = setLoading;
        this.onClose = onClose;
    }


    public async appleLogin(){
        try{
            const auth = await getAuth();
            let result = await signInWithPopup(auth, this.provider);
            let displayName = result.user?.displayName?.split(" ");
            let lastName = displayName?.pop();
            let firstName = displayName?.toString();
            let email = result.user?.email || "";
            let id = (getAdditionalUserInfo(result)?.profile as any).sub;

            let data = await LoginAPI.appleLogin(session)(email, id, firstName, lastName);
            if(data && data.access_token){
                this.handleSocialLogin(data.user, data.access_token);
            }
        }catch(error){
            this.setLoading(false);
            if(error instanceof Error && error?.message !== "The popup has been closed by the user before finalizing the operation."){
                this.setError(error);
                throw error;
            }
        }
    }
    public async twitterLogin(){
        LoginAPI.getTwitterLoginUrl(session)().then(({ url }) => {
			window.location.assign(url)
		})
    }

    public async facebookLogin(response: any){
        try{
            console.log("facebook response: " + JSON.stringify(response));
            if (response && response.accessToken) {
                let data = await LoginAPI.facebookLogin(session)(response.accessToken);
                if (data && data.access_token) {
                    this.handleSocialLogin(data.user, data.access_token);
                    return;
                }
            }
            this.setLoading(false);
        }catch(error){
            this.setLoading(false);
            if(error instanceof Error)
                this.setError(error);
            throw error;
        }
    }

    public async facebookLoginError(error: FailResponse){
        this.setLoading(false);
        console.log("facebook error: " + JSON.stringify(error));
        throw error;
    }


    public async discordLogin(setCookie: (name: "discordCSRToken", value: any, options?: (CookieSetOptions | undefined)) => void){
        const stateToken = uuidv4();
        setCookie("discordCSRToken", stateToken, {domain: ".dashradio.com", maxAge: 600, secure: true, sameSite: "strict"});
        console.log("state toeken: " + stateToken);
        const url = discordUrl(stateToken);
        window.location.assign(url);
    }
    public async emailLogin(email: string, password: string, reloadWindow?: boolean){
        try{
            this.setLoading(true);
            let data = await LoginAPI.login(session)(email, password);
            if (data && data.access_token) {
                this.handleSocialLogin(data.user, data.access_token);
                if (reloadWindow) {
                    setTimeout(function () {
                        window.location.reload()
                    }, 2000)
                }
            }
        }catch(error){
            this.setLoading(false);
            if(error instanceof Error)
                this.setError(error);
            throw error;
        }
    }

    private handleSocialLogin(user: User, access_token: string){
        session.session_token = access_token
        localStorage.setItem('userID', user.id.toString())
        this.setError(undefined)
        //dispatch session to redux store
        this.dispatch(setSession(access_token, user))
        PresetsAPI.getAllPresets(session)().then(presets => {
            this.setLoading(false)
            const dashboard = presets.filter(preset => Boolean(preset.station && preset.station.enabled)).map(preset => preset.station_id)
            this.dispatch(setFavorites(dashboard))
            this.onClose()
        }).catch(err => {
            this.setLoading(false)
            this.onClose()
        })
    }
    public static handleOAuthCallbackLogin(data: LoginResponse, dispatch: Dispatch<any>){
        session.session_token = data.access_token
        localStorage.setItem('userID', data.user.id.toString())

        // dispatch session to redux store
        dispatch(setSession(data.access_token, data.user))

        PresetsAPI.getAllPresets(session)().then(presets => {
            const dashboard = presets.filter(preset => Boolean(preset.station && preset.station.enabled)).map(preset => preset.station_id)
            dispatch(setFavorites(dashboard))
            setPageActive(Page.HOME)
        })
    }


}
