export default
`If you’re having problems using this website please try the following steps. If your problem is still not solved afterwards please don’t hesitate to contact us.

1. Make sure that your browser is up to date. We recommend using the latest version of Google Chrome.

2. If you are using an older browser (not recommended) you’ll also need the latest version of Adobe Flash Player.

3. Make sure that your browser is set to accept cookies.

4. Make sure that your browser allows JavaScript.

5. Make sure that you don’t have any content blocking browser plugins installed (Adblock Plus, Disconnect etc.). If you are using such plugins make sure dashradio.com is whitelisted and no content is blocked.

Have fun using Dash!
`