import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PersistentReduxStoreProvider from '../../GlobalStore';
import { ReduxAppState } from '../../reducers';
import { ICON_HEART_UNUSED, ICON_HEART_USED } from '../../Icons';
import {handleStationFavorisation} from '../../helper/StationFavHelper';

interface Props {
    station: any
}

const StationHeart: React.FC<Props> = React.memo((props) => {
    const { station } = props

    const authToken = useSelector((state: ReduxAppState) => state.login.auth_token)
    const active = useSelector((state: ReduxAppState) => Boolean(state.allStations.favorites && station.id && state.allStations.favorites.includes(station.id)))
    const dispatch = useDispatch()

    const handleClick = () => {
        handleStationFavorisation(station, active, authToken, dispatch);
    }

    return (
        <div className="station-heart">
            {active ?
                <img alt="Unfavorite" src={ICON_HEART_USED} width='14px' style={{ display: 'flex', alignItems: 'top', marginTop: '1px', color: '#FF0000', cursor: 'pointer' }} onClick={() => handleClick()} />
                :
                <img alt="Favorite" src={ICON_HEART_UNUSED} width='14px' style={{ display: 'flex', alignItems: 'top', marginTop: '1px', color: '#FF0000', cursor: 'pointer' }} onClick={() => handleClick()} />
            }
        </div>
    )
})

export default React.memo((props: any) => (
    <PersistentReduxStoreProvider>
        <StationHeart {...props} />
    </PersistentReduxStoreProvider>
))
