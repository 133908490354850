import React, { useState, useRef } from 'react';
import { IconButton, CircularProgress, useMediaQuery, DialogContent } from '@mui/material';
import clsx from 'clsx'
import Modal from '@mui/material/Modal';
import AppSimpleButton from './views/AppSimpleButton';
import AppSimpleInput from './views/AppSimpleInput';
import AppText from './views/AppText';
import * as EmailValidator from 'email-validator';
import { DashThemeLight, DashThemeDark } from '../Themes';
import { useDispatch } from 'react-redux'
import CloseIcon from '@mui/icons-material/CloseOutlined';
import { Page } from './AppDrawer'
import FacebookLogin from '@greatsumini/react-facebook-login';
import FadeInHOC from './ui-elements/FadeInHOC';
import { AppleLoginButton, DiscordLoginButton, FacebookLoginButton, TwitterLoginButton } from './SocialLoginButton';
import Spacer from './ui-elements/Spacer';
import Backdrop from '@mui/material/Backdrop';
import { setPageActive } from './views/AppDrawerComponents/Functions';
import LoginHelper from "../helper/LoginHelper";
import {useCookies} from "react-cookie";
import dialogClasses from "./Dialog.module.css";

interface Props {
	onClose: () => void,
	onNoAccountWithEmail?: (input: { email: string, password: string }) => void,
	onSignupClick: () => void,
	onForgotPasswordClick: () => void,
	sonos?: boolean
}


export const LoginDialog: React.FC<Props> = (props) => {
	const { onClose, onNoAccountWithEmail } = props

	const emailInput = useRef(null)
	const passwordInput = useRef(null)

	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState<Error | undefined>(undefined)

	const dispatch = useDispatch()

	const loginHelper: LoginHelper = new LoginHelper(dispatch, setError, setLoading, onClose);

	const handleLogin = () => {
		loginHelper.emailLogin(email, password, props.sonos).catch(handleSocialLoginError);
	}
	const handleSocialLoginError = (err: Error) => {
		//no account with provided email
		if (err && err['status'] === 404) {
			onNoAccountWithEmail && onNoAccountWithEmail({ email, password })
		}
	}

	var errorMessage
	const status: any = (error && error!['status']) || 400
	switch (status) {
		case 401:
		case 404:
			errorMessage = "Your email and password does not match a Dash Radio account."
			break
		default:
		case 400:
			if(!EmailValidator.validate(email))
				errorMessage = "Please provide a valid email address."
			else
				errorMessage = "Invalid credentials provided. Please check your input."
			break
	}

	return (
		<div className='dialog-popups' style={{ overflowX: 'hidden' }}>
			<div className='popup-header-container'>
				<div className='popup-title'>Hey there!</div>
				<DashThemeDark>
					<IconButton color="primary" onClick={() => onClose()} style={{ padding: 0 }}>
						<CloseIcon scale={24} />
					</IconButton>
				</DashThemeDark>
			</div>

			<form className={dialogClasses.content}>
				<div className='simple-input-container'>
					<AppSimpleInput
						inputRef={emailInput}
						placeholder="email@example.com"
						value={email}
						onChange={(value) => setEmail(value)}
						onSubmit={() => {
							passwordInput && (passwordInput.current as any).focus()
						}}
						popup />
				</div>
				<div className='simple-input-container'>
					<AppSimpleInput password
						inputRef={passwordInput}
						placeholder="password"
						value={password}
						onChange={(value) => setPassword(value)}
						onSubmit={handleLogin}
						popup />
				</div>

				<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%', marginBottom: 7 }}>
					<div className='popup-button-container'>
						<AppSimpleButton disabled={loading} onClick={handleLogin} popup>Sign in</AppSimpleButton>
						{
							loading &&
							<DashThemeLight>
								<CircularProgress color="primary" style={{ width: 24, height: 24, margin: '0px 16px', lineHeight: 0, color: '#ff3f90' }} />
							</DashThemeLight>
						}
					</div>
				</div>
				<div className='popup-text-container'>
					<AppText popup>
						<span className={dialogClasses.link} onClick={() => props.onForgotPasswordClick()}>Forgot your password?</span>
					</AppText>
				</div>

				{/* placeholder for error messages */}
				{
					error && errorMessage &&
					<div style={{ paddingTop: 16, paddingBottom: 16, paddingLeft: 4, paddingRight: 4 }}>
						{
							error && errorMessage && <AppText className={dialogClasses.error}>{errorMessage}</AppText>
						}
					</div>
				}

				{/* social login */}
				{
					!(error && errorMessage) &&
					<SocialLoginSection isLoading={loading} setLoading={setLoading}
						loginHelper={loginHelper} />
				}
				<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%', marginTop: 12 }}>
					<div className='popup-button-container'>
						<AppSimpleButton onClick={() => props.onSignupClick()} email popup>Sign Up with Email</AppSimpleButton>
					</div>
				</div>

				{/* footer */}
				<Spacer size={8} />
				<div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					<div style={{ width: 275 }}>
						<AppText popup>By <span className={clsx(dialogClasses.link)} onClick={() => props.onSignupClick()}>signing up</span>, you agree to our <span className={dialogClasses.link} onClick={() => setPageActive(Page.TERMS_OF_SERVICE)}>Terms and Conditions</span> and <span className={dialogClasses.link} onClick={() => setPageActive(Page.PRIVACY_POLICY)}>Privacy Policy</span>.</AppText>
					</div>
				</div>
			</form>

		</div>
	)
}

export const SocialLoginSection: React.FC<{loginHelper: LoginHelper, setLoading: (loading: boolean) => void, isLoading: boolean }> = (props) => {
	const [,setCookie,] = useCookies(['discordCSRToken']);

	return (<>
		<div className='social-buttons-container'>
			<FacebookLogin
				appId="864215646940689"
				autoLoad={false}
				fields="name,email,picture"
				render={renderProps => (<FacebookLoginButton onClick={() => {
					if(!props.isLoading && renderProps.onClick){
						props.setLoading(true);
						renderProps.onClick();
					}
				}} />)}
				onProfileSuccess={(response) => props.loginHelper.facebookLogin(response)}
				onFail={err => props.loginHelper.facebookLoginError(err)}
				/>
			<div style={{ marginTop: '12px' }} />
			<TwitterLoginButton onClick={() => {
				if(!props.isLoading){
					props.setLoading(true);
					props.loginHelper.twitterLogin();
				}
			}} />
			<div style={{ marginTop: '12px' }} />
			<AppleLoginButton onClick={() => {
				if(!props.isLoading){
					props.setLoading(true);
					props.loginHelper.appleLogin();
				}
			}}/>
			<div style={{ marginTop: '12px' }} />
			<DiscordLoginButton onClick={() => {
				if(!props.isLoading){
					props.setLoading(true);
					props.loginHelper.discordLogin(setCookie);
				}
			}} />
		</div>
	</>);
}

export const LoginDialogModal: React.FC<{ open: boolean, sonos?: boolean } & Props> = (props) => {
	const handleClose = () => props.onClose()
	const { onForgotPasswordClick, onNoAccountWithEmail, onSignupClick } = props

	return (
		<Modal open={props.open} onClose={handleClose} BackdropProps={{ timeout: 800 }} BackdropComponent={Backdrop}>
			<div>
				<FadeInHOC>
					<LoginDialog onClose={handleClose} {...{ onForgotPasswordClick, onNoAccountWithEmail, onSignupClick }} sonos={props.sonos} />
				</FadeInHOC>
			</div>
		</Modal>
	)
}

export default LoginDialogModal
