export default
`We may at our discretion change the terms of this Agreement at any time and will post a copy of the amended Agreement on the Site and on the Applications. We will also update the “Last Updated” date in any such amended Agreement. Such changes shall be effective upon posting by us, and if you use the Dash Radio Service after that date, your use will constitute acceptance of the revised terms and conditions.

However, no revisions to this Agreement will apply to any dispute between you and Dash Radio that arose prior to the date of such revision. If you do not agree to, or cannot comply with this Agreement, as amended, then you must stop using the Dash Radio Service and cancel your Dash Radio account(s). We encourage you to check the Dash Radio policies on the Applications or Site frequently.

END USER LICENSE AGREEMENT AND TERMS OF SERVICE

EFFECTIVE DATE: August 18, 2014

This End User License Agreement and Terms of Service (the “EULA”) is a binding contract between you, an individual user (“you”) and Dash Radio Inc. (“Dash Radio”, “us” or “we”) governing your use of the Dash Radio mobile application (the “App”) and affiliated website located at www.dashradio.com (the “Website”) (the App and the Website are, collectively, the “Service”).
BY ACCESSING THE WEBSITE OR BY INSTALLING OR OTHERWISE ACCESSING OR USING THE APP, YOU AGREE THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE BOUND BY THE EULA. IF YOU DO NOT AGREE, YOU MAY NOT USE THE SERVICE.

To have a copy of the EULA and the Dash Radio Privacy Policy sent to you, contact Dash Radio at eula@dashradio.com.

1. Summary of Material Terms. As provided in greater detail in the EULA (and without limiting the express language of the EULA), you acknowledge the following:

a. the App is licensed, not sold to you, and that you may use the App only as set forth in the EULA;

b. the use of the App and Website may be subject to separate third party terms of service and fees, including, without limitation, your mobile network operator’s (the “Carrier”) terms of service and fees, including fees charged for data usage and overage, which are your sole responsibility;

c. you consent to the collection, use, and disclosure of your personally identifiable information in accordance with the Dash Radio Privacy Policy (the “Privacy Policy”), including with respect to the collection of location information;

d. the App and Website are provided “as is” without warranties of any kind and Dash Radio’s liability to you is limited;

e. disputes arising hereunder will be resolved by binding arbitration;

f. by accepting the EULA, as provided in greater detail in Section 20 of the EULA, you and Dash Radio are each waiving the right to a trial by jury or to participate in a class action;

g. the App requires access to the following services on your mobile device: location and, if you are using Dash Radio on an Android device, your device identifier; and

h. if you are using the App on an iOS-based device, you agree to and acknowledge the “Notice Regarding Apple,” below.

2. General.

a. Description. The Service provides Users (as defined below) with a variety of noninteractive radio channels, curated and programmed for you by some of radio’s finest talent.

b. Acceptance of Privacy Policy. Your use of the Service is subject to the Privacy Policy, which is available at http://a.dashradio.co/privacy.html, and is hereby incorporated by reference into the EULA. By using the Service you agree that you have read, understood, and agree to the data collection, use, and disclosure provisions set forth in the Privacy Policy.

c. Third Party Fees. Your use of the Service may incur third party fees, such as fees charged by your Carrier for data usage, and may be subject to third party terms, such as your Carrier’s terms of service, and you agree to pay all such fees and abide by all such terms. You acknowledge and agree that you are solely responsible for all such fees incurred by you for use of the Service.

d. Service Security. You are prohibited from violating, or attempting to violate, the security of the Service. Any such violations may result in criminal and/or civil penalties against you, as well as the termination of your privilege to use the Service, at Dash Radio’s sole discretion. Dash Radio reserves the right to investigate any alleged or suspected violations and, if a criminal violation is suspected, refer such suspected violation to the appropriate law enforcement agencies and cooperate fully with such investigations, including, but not limited to, the disclosure of any or all of your activities on or related to the Service.

e. Changes to the EULA. The Service is owned and operated by Dash Radio. Dash Radio reserves the right to revise the EULA in its sole discretion at any time and without prior notice to you other than by posting the revised EULA within the App and on the Website. Revisions to the EULA are effective upon posting. The EULA will be identified as of the most recent date of revision. Your continued use of the Service after a revised version of the EULA has been posted by Dash Radio constitutes your binding acceptance of such revised EULA. Notwithstanding the preceding sentences of this Section 2.e, no revisions to the EULA will apply to any dispute between you and Dash Radio that arose prior to the effective date of such revision.

f. Eligibility. If you are using or opening an account on the Service on behalf of a company, entity, or organization (each a “Subscribing Entity”), then you represent and warrant that you: (i) are an authorized representative of that Subscribing Entity with the authority to bind such entity to the EULA and (ii) agree to be bound by the EULA on behalf of such Subscribing Entity.

g. Children. THE SERVICE IS NOT FOR PERSONS UNDER THE AGE OF 13 OR TO ANY USERS PREVIOUSLY SUSPENDED OR REMOVED FROM THE SERVICE BY DASH RADIO. In order to use the Service, you must either be 18 years of age or possess parental or guardian consent, and you must be fully able and competent to enter into the terms, conditions, obligations, affirmations, representations, and warranties set forth in the EULA and to abide by and comply with the EULA. If you are under 18 years of age, by using the Service you certify that your parent or guardian has consented to your use of the Service and to the EULA on your behalf, and you acknowledge and agree that your use of the Service is at their discretion. Until you are 18 years old, your parent or guardian may ask us to modify, deny access to, or terminate your account and we may do so at their request or at any time, for any reason, without notice and liability. BECAUSE THE SERVICE MAY PROVIDE ACCESS TO MUSIC OR OTHER CONTENT THAT CONTAINS EXPLICIT CONTENT INCLUDING STRONG LANGUAGE OR DEPICTIONS OF VIOLENCE, SEX OR SUBSTANCE ABUSE, PARENTAL DISCRETION IS ADVISED FOR ALL USERS AGES 13 TO 17.

h. Evolving Nature of Service. The Service is continually evolving and changes to the Service may be made at any time. If you don’t like the Service or in any way disagree with the EULA, we hope you will let us know and give us constructive feedback. Please feel free to e-mail us at any time at eula@dashradio.com.

i. Guidelines. When using the Service, you will be subject to any additional posted guidelines or rules applicable to specific services and features which may be posted from time to time (the “Guidelines”). All Guidelines are hereby incorporated by reference into these Terms. Terms is to discontinue your use of the Service.

3. Scope of License to Users.

a. License Grant to You. The Service is licensed, not sold, to you for use only under the terms of the EULA. Subject to your complete and ongoing compliance with the terms and conditions of the EULA, Dash Radio hereby grants you a personal, limited, revocable, non-transferable license to (i) use the App on compatible, authorized devices that you own or control and (ii) access and use the Website, in both instances solely for your own use or for the Subscribing Entity on whose behalf you are authorized to act.

b. License Limitations. You may not modify, alter, reproduce, or distribute the App. You may not directly rent, lease, lend, sell, redistribute or sublicense the Service. You may not copy, decompile, reverse engineer, disassemble, attempt to derive the source code of, modify, or create derivative works of any portion of the Service, any updates, or any part thereof (except as and only to the extent any foregoing restriction is prohibited by applicable law or the terms of any External Service (as defined below)), nor attempt to disable or circumvent any security or other technological measure designed to protect the Service or any content available through the Service, including, but not limited to, any sound recordings accessible through the Service, and any musical works embodied therein. If you breach these license restrictions, or otherwise exceed the scope of the licenses granted in the EULA, then you may be subject to prosecution and damages, as well as liability for infringement of intellectual property rights, and loss of access to the Service.

c. Applicability to Updates. The terms of the EULA will govern any updates provided to you by Dash Radio that replace and/or supplement the App, unless such upgrade is accompanied by a separate license or revised EULA, in which case the terms of that license or revised EULA will govern.

4. Registration and Eligibility.

a. Access. Access to the Service is only available to registered users (“Users”). If you do not agree to register and accept the terms of the EULA, then you are not permitted to use the Service.

b. Log-In Credentials. You may have the option to register for the Service by connecting to your Facebook account, Twitter account, or providing your e-mail address and a password. Your login information any other information you choose to provide about yourself will be treated in accordance with our Privacy Policy. You are solely responsible for maintaining the confidentiality and security of your credentials, and you will remain responsible for all use of your credentials, and all activity emanating from your account on the App (your “Account”), whether or not such activity was authorized by you.

c. Theft of Log-In Credentials. If your log-in credentials are lost or stolen, or if you believe that unauthorized third parties have accessed your Account, you should notify Dash Radio immediately via e-mail to info@dashradio.com, and should change your password at the earliest possible opportunity. Dash Radio will not be liable for any loss or damage arising from unauthorized use of your credentials prior to you notifying Dash Radio of such unauthorized use or loss of your credentials.

d. Accuracy of Information; Rejection of Registered Names. We reserve the right to disallow, cancel, remove or reassign certain usernames and permalinks in appropriate circumstances, as determined by us in our sole discretion, and may, with or without prior notice, suspend or terminate your Account if activities occur on your Account which, in our sole discretion, would or might constitute a violation of the EULA, or an infringement or violation of the rights of any third party, or of any applicable laws or regulations, including, but not limited to, trademark rights, copyrights, or the rights of privacy or publicity. You agree to provide true, accurate, current, and complete information about yourself as requested in any registration forms required by Dash Radio. You also agree to update the information about yourself promptly, and as necessary, to keep it current and accurate. If messages sent to an e-mail address provided by you are returned as undeliverable, Dash Radio reserves the right to terminate your Account immediately with or without notice to you and without any liability to you or any third party.

5. Content You Submit; License Grants From You. We may provide you with the opportunity to post a comment or review on the Service.

a. You Own the Content You Submit. Any and all audio, musical works, audiovisual, video, text, photographs, pictures, graphics, artwork, comments, and other content, data or information (collectively, “Content”) that you upload, store, transmit, submit, exchange or make available to or via the Service (“Your Content”) is generated, owned, and controlled solely by you, and not by Dash Radio. Dash Radio does not claim any ownership rights in Your Content, and you hereby expressly acknowledge and agree that you own any Content you submit and that such Content, including any fees, royalties, payments, credits or other obligations associated therewith, remains your sole responsibility.

b. License Grants to Dash Radio.

i. Notwithstanding Section 5.a, by uploading and/or submitting you Content to the Service, and in consideration of the opportunity to have Your Content exposed to and consumed by other Users and the promotional value received therefrom, you hereby grant to Dash Radio (and Dash Radio’s assignees, designees, successors, licensees, and sub-licensees) an unrestricted, assignable, sublicensable, revocable, royalty-free license throughout the world and during the term of the EULA to reproduce (including in timed relation to visual images in audiovisual works uploaded to the Service by you (but not in works uploaded by third parties)), distribute, publicly display, transmit, communicate to the public, publicly perform on an interactive and/or noninteractive basis (including by means of digital audio transmissions) on a through-to-the-audience basis, create derivative works from, and otherwise use and exploit (collectively, “Use”) all Content you upload to or through the Service, on an item-by-item basis, through any media and formats now known or hereafter developed, for the purposes of: (i) transmitting or distributing Your Content to Users; (ii) sharing information about Your Content with third parties for any Use or purpose selected by Dash Radio and permitted in the EULA; and (iii) advertising, marketing, and promoting Dash Radio, the Service, and the availability of Your Content on and through the Service.

ii. License for Name, Image, Voice, and Likeness. You further grant Dash Radio a royalty free license to Use your name, image, voice, and likeness made available by or on your behalf through the Service in conjunction with advertising, marketing, or promoting you, Your Content, Dash Radio or the Service. The foregoing license in the immediately preceding sentence shall survive the termination of your Account with respect to any of Your Content submitted or uploaded to the Service prior to such termination.

iii. License for Advertising. You further grant Dash Radio the right to include Advertising (as defined below, whether audio, display, or audiovisual) in and around Your Content uploaded to the Service.

c. Limited Waiver of Rights. By uploading Content to or through the Service you waive any rights to prior inspection or approval of any Use of Your Content in marketing or promotional materials related to such Content created by Dash Radio. You further waive any and all rights of privacy, publicity, or any other rights of a similar nature in connection with Your Content, or any portion thereof. To the extent any moral rights are not transferable or assignable, you hereby waive and agree never to assert any and all moral rights, or to support, maintain or permit any action based on any moral rights that you may have in or with respect to any Content you upload to or through the Service, during the term of the EULA. You expressly release Dash Radio and all of Dash Radio’s agents, partners, subsidiaries, affiliates, licensees, successors, and assigns from any and all claims, demands, liabilities, or causes of action, whether now known or unknown, for defamation, copyright infringement, violation of moral rights, and invasion of the rights to privacy, publicity, or personality or any similar matter, or based upon or relating to the Use of Your Content as authorized in the EULA.

d. Through-to-the-Audience Rights. All of the rights granted in the EULA by you are provided on a through-to-the-audience basis, meaning, by way of example and not limitation, the owners or operators of External Services (as defined in Section 8.a) will not have any separate liability to you or any other third party (e.g., a PRO) for Content publicly performed, publicly displayed, made available or communicated to the public through such External Services via the Service.

e. Do No Upload Content for Which You Lack the Necessary Rights. You must not upload, store, host, distribute, send, transmit, display, perform, make available or otherwise communicate to the public any Content to which you do not hold the necessary rights. In particular, any unauthorized use of copyrighted material within Your Content (including by way of reproduction, distribution, modification, adaptation, public display, public performance, preparation of derivative works, making available or otherwise communicating to the public via the App) without full ownership and/or control, or written and valid permission to do so, may constitute an infringement of third party rights and is strictly prohibited. Any such infringements may result in termination of your access to the Service as described in Section 14, damages against you for breach of the EULA, and civil litigation or criminal prosecution against you.

f. Representations and Warranties With Respect to Content You Upload. Except for materials provided, to you by Dash Radio through the Service, you represent, warrant, and covenant that (A) you either are the sole and exclusive owner of all of Your Content that you submit to the Service, or you have all rights, licenses, consents, and releases that are necessary to grant to Dash Radio the rights in Your Content as contemplated under the EULA, and (B) neither the Content you submit to the Service nor your creation of, accessing, posting, submission or transmission of Your Content or Dash Radio’s Use of Your Content (or any portion thereof) as permitted in the EULA will (1) infringe, misappropriate or violate the rights of any party or entity, including a third party’s patent, copyright, trademark, trade secret, moral rights, rights of publicity, rights of privacy or other intellectual property or proprietary rights, (2) constitute or result in defamation, libel, slander, or the violation of any applicable law or regulation (including, without limitation, restrictions on obscenity, child pornography, wiretap laws or other similar restrictions on nonconsensual recording, or otherwise), or (3) require Dash Radio to obtain any licenses from or make any payments in any amounts to any third party throughout the world.

g. Prohibition on Uploading Objectionable Content. You agree not to submit Content that: (i) is or could be interpreted to be infringing, defamatory, libelous, inaccurate, unlawful, harmful, threatening, abusive, harassing, vulgar, offensive, obscene, pornographic, objectionable, hateful, or promotes discrimination, bigotry, racism, or hatred (collectively, “Objectionable Content”), as determined by Dash Radio in its sole discretion; or (ii) introduces viruses, time-bombs, worms, cancel bots, Trojan Horses and/or other harmful or malicious code.

6. Fees. Dash Radio reserves the right to charge a fee for using the Dash Radio Service. If Dash Radio elects to charge a fee for the use of the Dash Radio Service, Dash Radio will provide you with notice of such fees. You do not have an obligation to continue using the Dash Radio Service should Dash Radio exercise its right to charge a fee. You are responsible for paying any fees due for the use of the Dash Radio Service upon your use of the Dash Radio Service following actual or constructive knowledge of such fees, as well as any governmental taxes imposed on your use of the Dash Radio Service, including, but not limited to, sales, use or value-added taxes.

7. Third Party Materials; Dash Radio Content.

a. You understand that by using the App, you may encounter data, information, applications, materials and other content from third parties, including other Users (collectively, “Third Party Materials”), and data, information, applications, materials and other content from Dash Radio (collectively, “Dash Radio Content” and, together with Third Party Materials, but excluding Your Content, “Service Content”), that may be offensive, indecent, or objectionable, and which may or may not be identified as having explicit language or other material. Nevertheless, you agree to use the Service at your sole risk and that Dash Radio shall not have any liability to you for any Service Content that may be found to be offensive, indecent, or that is inaccurate, incomplete, untimely, invalid, illegal, indecent, of poor quality or otherwise objectionable. You use the Service, and rely upon any Service Content accessible through the Service, at your sole risk.

b. You agree and understand that the Service contains proprietary content, information and material that is protected by applicable intellectual property and other laws, including, but not limited to, copyright, and that you will not use such proprietary content, information or materials except for permitted use of the Service. No portion of the Service may be reproduced or resold, leased, or rented by you in any form or by any means. You agree not to modify, rent, lease, loan, sell, distribute, or create derivative works based on the Service, in any manner, and you shall not exploit the Service in any unauthorized way whatsoever (e.g., hack the Service), including but not limited to, by trespass or burdening network capacity. You further agree not to use the Service in any manner to harass, abuse, stalk, threaten, defame or otherwise infringe or violate the rights of any other party, and that Dash Radio is not in any way responsible for any such use by you, nor for any harassing, threatening, defamatory, offensive, illegal or other harmful messages or transmissions that you may receive as a result of using the Service.

c. In addition, third party services and Service Content that may be accessed from, displayed on or linked to from your device are not available in all languages or in all countries. Dash Radio makes no representation that the Service, any third party services, and Service Content are appropriate or available for use in any particular location. To the extent you choose to access such services or materials, you do so at your own initiative and are responsible for compliance with any and all applicable laws. Dash Radio and its licensors reserve the right to change, suspend, remove, or disable access to the Service at any time without notice. In no event will Dash Radio be liable for the removal or disabling of access to all or any portion of the Service at any time. Dash Radio may also impose limits on the use of or access to all or any portion of the Service at any time, in any case and without notice or liability. You are solely responsible for maintaining back-up copies of any Content you upload to the Service. Although we may attempt to notify Users prior to the disabling of access to all or any portion of the Service, we are not obligated to provide such notice and you should not rely upon the continued availability of the Service for any Content.

8. Third Party Websites, Applications and Services.

a. The Application may provide you with access to third party websites, databases, networks, servers, information, software, programs, systems, directories, applications, or products or services (collectively, “External Services”).

b. No Control Over External Services. Dash Radio does not have or maintain any control over External Services, and is not and cannot be responsible for their content, operation or use. By linking or otherwise displaying information from or providing access to any External Services, Dash Radio does not give any representation, warranty or endorsement, express or implied, with respect to the legality, accuracy, quality or authenticity of content, information or services provided by such External Services.

c. Terms of External Services. External Services may have their own terms of use and/or privacy policy, and may have different practices and requirements to those operated by Dash Radio with respect to the Service. You are solely responsible for reviewing any terms of use, privacy policy or other terms governing your use of these External Services, which you use at your own risk. You are advised to make reasonable inquiries and investigations before entering into any transaction, financial or otherwise, and whether online or offline, with any third party related to any External Services.

d. Disclaimer of Liability for External Services. You are solely responsible for taking the precautions necessary to protect yourself from fraud when using External Services, and to protect your computer systems from viruses, worms, Trojan horses, and other harmful or destructive content and material that may be included on or may emanate from any External Services. Dash Radio disclaims any and all responsibility or liability for any harm resulting from your use of External Services, and you hereby irrevocably waive any claim against Dash Radio with respect to the content or operation of any External Services.

9. Your Use of the Service, Content, and Service Content. Your right to use the Service is expressly conditioned on the following:

a. You may access the Service solely as intended through the provided functionality of the Service and as permitted under the EULA.

b. Unless expressly permitted, you agree not to copy, reproduce, distribute, publish, display, perform, transmit, stream or broadcast any part of the Service without Dash Radio’s prior written authorization, including, by way of example and not limitation, by doing or engaging in any of the following without Dash Radio’s express written consent:

i. altering, defacing, mutilating or otherwise bypassing any approved software through which the Service is made available; and

ii. using any trademarks, service marks, design marks, logos, photographs or other content belonging to Dash Radio or obtained from the Service.

c. You agree not to bypass, circumvent, damage or otherwise interfere with any security or other features of the App designed to control the manner in which the Service is used, harvest or mine Service Content from the Service, or otherwise access or use the Service in a manner inconsistent with individual human usage.

d. You agree not to undertake, cause, permit or authorize the translation, reverse engineering, disassembling or hacking of any aspect of the Service, including any Service Content available on or through the Service, or attempt to do any of the foregoing, except and solely to the extent permitted by the EULA, the authorized features of the Service, or by law, or otherwise attempt to use or access any portion of the Service other than as intended by Dash Radio.

e. You agree not to use, display, mirror, frame or utilize framing techniques to enclose the Service, including any Service Content available on or through the Service, or any portion thereof, through any other application or website, unless and solely to the extent Dash Radio makes available the means for embedding any part of the Service or Service Content.

f. You agree not to access, tamper with, or use non-public areas of the Service, Dash Radio’s (and its hosting company’s) computer systems and infrastructure, or the technical delivery systems of Dash Radio’s providers.

g. You agree not to harass, abuse, harm or advocate or incite harassment, abuse or harm of another person or group, including Dash Radio employees and other Users.

h. You agree not to provide any false personal information to Dash Radio or any other User, or create a false identify or impersonate another person or entity in any way.

i. You agree not to create a new account with Dash Radio, without Dash Radio’s express written consent, if Dash Radio has previously disabled an account of yours.

j. You agree not to solicit, or attempt to solicit, personal information from other Users.

k. You agree not to restrict, discourage or inhibit any person from using the Service, disclose personal information about a third person on the Service or obtained from the Service without the consent of such person, or collect information about Users.

l. You agree not to use the Service, without Dash Radio’s express written consent, to communicate or facilitate any commercial advertisement or solicitation.

m. You agree not to gain unauthorized access to the Service, to other Users’ accounts, names or personally identifiable information, or to other computers or websites connected or linked to the Service.

n. You agree not to post, transmit or otherwise make available any virus, worm, spyware or any other computer code, file or program that may or is intended to disable, overburden, impair, damage or hijack the operation of any hardware, software or telecommunications equipment, or any other aspect of the Service or communications equipment and computers connected to the Service.

o. You agree not to interfere with or disrupt the Service, or networks or servers connected to the Service, or violate the regulations, policies or procedures of such networks or servers.

p. You agree not to violate any applicable federal, state or local laws or regulations or the EULA.

`